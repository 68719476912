import React, { useEffect, useContext } from 'react';
import { Link, navigate } from 'gatsby';
import Img from 'gatsby-image';
import { AppContext, App } from '../components/app';
import Layout from '../components/layout';
import Menu from '../components/menu';
import Footer from '../components/footer';
import Copy2Cliboard from '../util/copy2clipboard';

const ContactPage = ({ location }) => {
    const context = useContext(AppContext);
    const { state, dispatch } = context;

    useEffect(() => {
        if (!state || !state.affiliateId) {
            navigate('/login');
        }
    }, []);

    const copyToClipboard = e => {
        Copy2Cliboard.select_all_and_copy(e.target);
    };

    if (!state || !state.affiliateId) {
        return <div></div>;
    }
    return (
        <Layout>
            <Menu location={location} />

            <h1 className="huge nohyphens">Links</h1>
            <p className="padding-bottom-0">
                If you download and place our&nbsp;photos or videos on&nbsp;your
                website, there should be at least one visible link to W4B or
                BCASH4YOU. For special discounts and landing pages please
                contact us.
            </p>
            <div className="spacer spacer-80">&nbsp;</div>

            <div className="forms">
                <div className="forms-fix">
                    <form>
                        <div className="noborder">
                            <div className="form-group">
                                <input
                                    type="text"
                                    id="link001"
                                    className="has-value "
                                    rows="2"
                                    onFocus={copyToClipboard}
                                    value={
                                        'http://refer.ccbill.com/cgi-bin/clicks.cgi?CA=930360-0000&PA=' +
                                        state.affiliateId
                                    }
                                    readOnly
                                />
                                <label
                                    className="control-label"
                                    htmlFor="link001"
                                >
                                    Your affiliate link
                                </label>
                            </div>
                        </div>
                        <div className="noborder">
                            <div className="form-group">
                                <input
                                    type="text"
                                    id="link002"
                                    className="has-value"
                                    rows="2"
                                    onFocus={copyToClipboard}
                                    value={`http://refer.ccbill.com/cgi-bin/clicks.cgi?CA=930360-0000&PA=${state.affiliateId}&HTML=https://www.watch4beauty.com/join`}
                                    readOnly
                                />
                                <label
                                    className="control-label"
                                    htmlFor="link002"
                                >
                                    Join now link
                                </label>
                            </div>
                        </div>
                        <div className="noborder">
                            <div className="form-group">
                                <input
                                    type="text"
                                    id="link003"
                                    className="has-value"
                                    rows="2"
                                    onFocus={copyToClipboard}
                                    value={`http://refer.ccbill.com/cgi-bin/clicks.cgi?CA=930360-0000&PA=${state.affiliateId}&HTML=https://www.watch4beauty.com/updates`}
                                    readOnly
                                />
                                <label
                                    className="control-label"
                                    htmlFor="link003"
                                >
                                    All updates link
                                </label>
                            </div>
                        </div>
                        <div className="noborder">
                            <div className="form-group">
                                <input
                                    type="text"
                                    id="link004"
                                    className="has-value"
                                    rows="2"
                                    onFocus={copyToClipboard}
                                    value={
                                        'http://fhg.bcash4you.com/w4b/potd/latest-issue.html?' +
                                        state.affiliateId
                                    }
                                    readOnly
                                />
                                <label
                                    className="control-label"
                                    htmlFor="link004"
                                >
                                    POTD latest update
                                </label>
                            </div>
                        </div>
                        <div className="noborder">
                            <div className="form-group">
                                <input
                                    type="text"
                                    id="link005"
                                    className="has-value"
                                    rows="2"
                                    onFocus={copyToClipboard}
                                    value={
                                        'http://fhg.bcash4you.com/w4b/potd/potd.html?' +
                                        state.affiliateId
                                    }
                                    readOnly
                                />
                                <label
                                    className="control-label"
                                    htmlFor="link005"
                                >
                                    POTD random photo
                                </label>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div className="spacer line spacer-80">&nbsp;</div>

            <div className="spacer spacer-80">&nbsp;</div>
            <h1 className="nohyphens">Discount link</h1>
            <p className="padding-bottom-0">
                We are running a special discount $14.99 for 30&nbsp;days
                instead of the regular $29.99 letting you advertise a 50%
                discount. Link directs to a special discount landing page.
            </p>
            <div className="spacer spacer-80">&nbsp;</div>

            <div className="forms">
                <div className="forms-fix">
                    <form>
                        <div className="noborder">
                            <div className="form-group">
                                <input
                                    type="text"
                                    id="link006"
                                    className="has-value"
                                    rows="1"
                                    onFocus={copyToClipboard}
                                    value={`http://refer.ccbill.com/cgi-bin/clicks.cgi?CA=930360-0000&PA=${state.affiliateId}&HTML=https://www.watch4beauty.com/discount?discount`}
                                    readOnly
                                />
                                <label
                                    className="control-label"
                                    htmlFor="link006"
                                >
                                    50% OFF link
                                </label>
                            </div>
                        </div>
                        <div className="button-container">
                            <a
                                href={`http://refer.ccbill.com/cgi-bin/clicks.cgi?CA=930360-0000&PA=${state.affiliateId}&HTML=https://www.watch4beauty.com/discount?discount`}
                                className="button full"
                                target="_blank"
                                title="View"
                            >
                                View
                            </a>
                        </div>
                    </form>
                </div>
            </div>

            <div className="spacer spacer-16">&nbsp;</div>
            <div className="spacer spacer-80">&nbsp;</div>
            <img
                src={require('../images/trio-001-discount.jpg')}
                width="100%"
                height="auto"
                alt="W4B"
            />

            <Footer />
        </Layout>
    );
};

export default ContactPage;
